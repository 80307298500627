@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
	font-family: 'Proxima Nova';
	src: url('../src/assets/fonts/ProximaNova-Thin.eot');
	src: local('Proxima Nova Thin'), local('ProximaNovaT-Thin'),
		url('../src/assets/fonts/ProximaNova-Thin.eot?#iefix') format('embedded-opentype'),
		url('../src/assets/fonts/ProximaNova-Thin.woff') format('woff'),
		url('../src/assets/fonts/ProximaNova-Thin.ttf') format('truetype');
	font-weight: 100;
	font-style: normal;
}
@font-face {
	font-family: 'Proxima Nova';
	src: url('../src/assets/fonts/ProximaNova-ThinIt.eot');
	src: local('Proxima Nova Thin Italic'), local('ProximaNova-ThinIt'),
		url('../src/assets/fonts/ProximaNova-ThinIt.eot?#iefix') format('embedded-opentype'),
		url('../src/assets/fonts/ProximaNova-ThinIt.woff') format('woff'),
		url('../src/assets/fonts/ProximaNova-ThinIt.ttf') format('truetype');
	font-weight: 100;
	font-style: italic;
}

@font-face {
	font-family: 'Proxima Nova';
	src: url('../src/assets/fonts/ProximaNova-Light.eot');
	src: local('Proxima Nova Light'), local('ProximaNova-Light'),
		url('../src/assets/fonts/ProximaNova-Light.eot?#iefix') format('embedded-opentype'),
		url('../src/assets/fonts/ProximaNova-Light.woff') format('woff'),
		url('../src/assets/fonts/ProximaNova-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}
@font-face {
	font-family: 'Proxima Nova';
	src: url('../src/assets/fonts/ProximaNova-LightIt.eot');
	src: local('Proxima Nova Light Italic'), local('ProximaNova-LightIt'),
		url('../src/assets/fonts/ProximaNova-LightIt.eot?#iefix') format('embedded-opentype'),
		url('../src/assets/fonts/ProximaNova-LightIt.woff') format('woff'),
		url('../src/assets/fonts/ProximaNova-LightIt.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'Proxima Nova';
	src: url('../src/assets/fonts/ProximaNova-Regular.eot');
	src: local('Proxima Nova Regular'), local('ProximaNova-Regular'),
		url('../src/assets/fonts/ProximaNova-Regular.eot?#iefix') format('embedded-opentype'),
		url('../src/assets/fonts/ProximaNova-Regular.woff') format('woff'),
		url('../src/assets/fonts/ProximaNova-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'Proxima Nova';
	src: url('../src/assets/fonts/ProximaNova-RegularIt.eot');
	src: local('Proxima Nova Regular Italic'), local('ProximaNova-RegularIt'),
		url('../src/assets/fonts/ProximaNova-RegularIt.eot?#iefix') format('embedded-opentype'),
		url('../src/assets/fonts/ProximaNova-RegularIt.woff') format('woff'),
		url('../src/assets/fonts/ProximaNova-RegularIt.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Proxima Nova';
	src: url('../src/assets/fonts/ProximaNova-Semibold.eot');
	src: local('Proxima Nova Semibold'), local('ProximaNova-Semibold'),
		url('../src/assets/fonts/ProximaNova-Semibold.eot?#iefix') format('embedded-opentype'),
		url('../src/assets/fonts/ProximaNova-Semibold.woff') format('woff'),
		url('../src/assets/fonts/ProximaNova-Semibold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}
@font-face {
	font-family: 'Proxima Nova';
	src: url('../src/assets/fonts/ProximaNova-SemiboldIt.eot');
	src: local('Proxima Nova Semibold Italic'), local('ProximaNova-SemiboldIt'),
		url('../src/assets/fonts/ProximaNova-SemiboldIt.eot?#iefix') format('embedded-opentype'),
		url('../src/assets/fonts/ProximaNova-SemiboldIt.woff') format('woff'),
		url('../src/assets/fonts/ProximaNova-SemiboldIt.ttf') format('truetype');
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: 'Proxima Nova';
	src: url('../src/assets/fonts/ProximaNova-Bold.eot');
	src: local('Proxima Nova Bold'), local('ProximaNova-Bold'),
		url('../src/assets/fonts/ProximaNova-Bold.eot?#iefix') format('embedded-opentype'),
		url('../src/assets/fonts/ProximaNova-Bold.woff') format('woff'),
		url('../src/assets/fonts/ProximaNova-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}
@font-face {
	font-family: 'Proxima Nova';
	src: url('../src/assets/fonts/ProximaNova-BoldIt.eot');
	src: local('Proxima Nova Bold Italic'), local('ProximaNova-BoldIt'),
		url('../src/assets/fonts/ProximaNova-BoldIt.eot?#iefix') format('embedded-opentype'),
		url('../src/assets/fonts/ProximaNova-BoldIt.woff') format('woff'),
		url('../src/assets/fonts/ProximaNova-BoldIt.ttf') format('truetype');
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: 'Proxima Nova';
	src: url('../src/assets/fonts/ProximaNova-Extrabld.eot');
	src: local('Proxima Nova Extrabold'), local('ProximaNova-Extrabld'),
		url('../src/assets/fonts/ProximaNova-Extrabld.eot?#iefix') format('embedded-opentype'),
		url('../src/assets/fonts/ProximaNova-Extrabld.woff') format('woff'),
		url('../src/assets/fonts/ProximaNova-Extrabld.ttf') format('truetype');
	font-weight: 800;
	font-style: normal;
}
@font-face {
	font-family: 'Proxima Nova';
	src: url('../src/assets/fonts/ProximaNova-ExtrabldIt.eot');
	src: local('Proxima Nova Extrabold Italic'), local('ProximaNova-ExtrabldIt'),
		url('../src/assets/fonts/ProximaNova-ExtrabldIt.eot?#iefix') format('embedded-opentype'),
		url('../src/assets/fonts/ProximaNova-ExtrabldIt.woff') format('woff'),
		url('../src/assets/fonts/ProximaNova-ExtrabldIt.ttf') format('truetype');
	font-weight: 800;
	font-style: italic;
}

@font-face {
	font-family: 'Proxima Nova';
	src: url('../src/assets/fonts/ProximaNova-Black.eot');
	src: local('Proxima Nova Black'), local('ProximaNova-Black'),
		url('../src/assets/fonts/ProximaNova-Black.eot?#iefix') format('embedded-opentype'),
		url('../src/assets/fonts/ProximaNova-Black.woff') format('woff'),
		url('../src/assets/fonts/ProximaNova-Black.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}
@font-face {
	font-family: 'Proxima Nova';
	src: url('../src/assets/fonts/ProximaNova-BlackIt.eot');
	src: local('Proxima Nova Black Italic'), local('ProximaNova-BlackIt'),
		url('../src/assets/fonts/ProximaNova-BlackIt.eot?#iefix') format('embedded-opentype'),
		url('../src/assets/fonts/ProximaNova-BlackIt.woff') format('woff'),
		url('../src/assets/fonts/ProximaNova-BlackIt.ttf') format('truetype');
	font-weight: 900;
	font-style: italic;
}


@layer base {
	html {
		font-family: Proxima Nova
	}
}

@layer utilities {
	@variants responsive {

		.no-scrollbar::-webkit-scrollbar {
				display: none;
		}

		.no-scrollbar {
				-ms-overflow-style: none;  /* IE and Edge */
				scrollbar-width: none;  /* Firefox */
		}
	}
}

::-webkit-scrollbar
{
    width: 4px;
		height: 2px;
    background-color: transparent;
}

::-webkit-scrollbar-thumb
{
    border-radius: 10px;
    background-color: grey;
}